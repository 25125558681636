import type { PropType, Ref } from "vue";

import { nanoid } from "nanoid";
import { computed, reactive, toRef, toRefs } from "vue";

import type { TemplateRef } from "@/lib/composables";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { InputValue } from "@/lib/helpers/types";

import {
  decimals as decimalsProp,
  size,
  type as typeProp,
} from "@/lib/components/logic/atoms/input/props";
import { useNumberMasking } from "@/lib/components/logic/atoms/input/useNumberMasking";
import { useModel } from "@/lib/composables";
import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";

const scopedBase = propsDefinition({
  name: { type: String, required: true, default: () => nanoid(10) },
  type: typeProp,
  placeholder: { type: String, required: false },
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  pattern: { type: RegExp, required: false },
  autocomplete: { type: String, required: false },
  maxlength: { type: Number, required: false },
  minlength: { type: Number, required: false },
  size,
  prefixIcon: {
    type: String,
    required: false,
  },
  suffixIcon: {
    type: String,
    required: false,
  },
  inputRef: { type: Function as PropType<TemplateRef>, required: false },
});

const scopedTextOnly = propsDefinition({
  ...scopedBase,
  modelValue: {
    type: String as PropType<string | null | undefined>,
    default: null,
  },
});

const scoped = propsDefinition({
  ...scopedBase,
  modelValue: {
    type: [String, Number] as PropType<number | string | null | undefined>,
    default: null,
  },
  decimals: decimalsProp,
  decimalsFill: { type: Boolean, default: false },
});

const props = propsDefinition({
  ...scoped,
  modelValue: {
    type: [String, Number] as PropType<number | string | null | undefined>,
    default: null,
  },
  valid: { type: Boolean, default: false },
  invalid: { type: Boolean, default: false },
  describedBy: { type: String, required: false },
  id: { type: String, required: false },
});

type UseInputFieldAtomEmit = {
  (event: "blur" | "focus", name: string): void;
  (event: "click", pointerEvent: PointerEvent): void;
  (event: "keydown" | "keyup", keyboardEvent: KeyboardEvent): void;
  (event: "update:modelValue", value: number | string | null | undefined): void;
};

const emits = emitsDefinition([
  "update:modelValue",
  "keydown",
  "keyup",
  "click",
  "focus",
  "blur",
]);

type UseInputFieldAtomProps = DefineProps<typeof props>;

function use(props: UseInputFieldAtomProps, emit: UseInputFieldAtomEmit) {
  const propRefs = toRefs(props);

  const type = computed(() => {
    const typeMap = {
      number: "text",
    } as Record<string, string>;

    return typeMap[props.type as string] ?? props.type;
  });

  const inputmode = computed(() => {
    if (props.type !== "number") {
      return;
    }
    if (props.decimals) {
      return "decimal";
    }
    return "numeric";
  });

  const hasPrefixIcon = computed(() => !!props.prefixIcon);
  const hasSuffixIcon = computed(() => {
    return !!(
      props.suffixIcon ||
      props.loading ||
      props.valid ||
      props.invalid
    );
  });

  const pattern = computed(() => {
    return props.pattern ? String(props.pattern) : props.pattern;
  });

  const modelValue = useModel("modelValue", props, emit, { local: true });

  function useNoopMasking(modelValue: Ref<number | string | null | undefined>) {
    return {
      displayValue: computed({
        get: () => modelValue.value ?? "",
        set: (value) => (modelValue.value = value),
      }),
      getMasked: (newModel: string) => newModel,
    };
  }

  const masking = ((): {
    displayValue: Ref<number | string | undefined>;
    getMasked: (value: string) => string;
    getSanitized?: (value: InputValue<number | string>) => string;
  } => {
    // This does not support changing to a different masking dynamically

    if (props.type === "number") {
      return useNumberMasking({
        numberValue: modelValue,
        decimals: propRefs.decimals,
        decimalsFill: propRefs.decimalsFill,
      });
    }

    return useNoopMasking(modelValue);
  })();

  function onInput({ target }: InputEvent) {
    const inputValue = (target as HTMLInputElement).value;
    masking.displayValue.value = masking.getMasked(inputValue);
    if (inputValue !== masking.displayValue.value) {
      (target as HTMLInputElement).value = masking.displayValue.value;
    }
  }

  function onBlur() {
    if (masking.getSanitized) {
      masking.displayValue.value = masking.getSanitized(
        masking.displayValue.value,
      );
    }
    emit("blur", props.name);
  }

  function onFocus() {
    emit("focus", props.name);
  }

  return {
    input: {
      ref: props.inputRef,
      props: reactive({
        id: propRefs.id,
        name: propRefs.name,
        value: masking.displayValue,
        type,
        placeholder: propRefs.placeholder,
        autocomplete: propRefs.autocomplete,
        disabled: propRefs.disabled,
        required: propRefs.required,
        "aria-invalid": toRef(() =>
          props.invalid ? ("true" as const) : undefined,
        ),
        maxlength: propRefs.maxlength,
        minlength: propRefs.minlength,
        pattern,
        readonly: propRefs.readonly,
        "aria-describedby": propRefs.describedBy,
        inputmode,
      }),
      on: {
        click: (event: PointerEvent) => emit("click", event),
        input: onInput,
        blur: onBlur,
        focus: onFocus,
        keydown: (event: KeyboardEvent) => emit("keydown", event),
        keyup: (event: KeyboardEvent) => emit("keyup", event),
      },
    },
    size: propRefs.size,
    hasPrefixIcon,
    hasSuffixIcon,
  };
}

export type { UseInputFieldAtomEmit, UseInputFieldAtomProps };
export { emits, props, scoped, scopedTextOnly, use };
